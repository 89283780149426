.leaflet-container {
  height: 100vh;
}

body,h3 {
    margin: 0;
    padding: 0;
}
p {
    text-indent: 2em;
}
